<template>
    <div class="ielts-activity">
        <div class="content" v-if="courseData.length>0">
            <div class="lists">
                <CourseCard v-for="(course,index) in courseData" :courseData="course" :key="index"
                            detailPage="activityDetail"></CourseCard>
            </div>
        </div>
        <NoContent v-else :data="noContentData"></NoContent>
        <el-pagination
            @size-change="getActivityCourseList"
            @current-change="getActivityCourseList"
            v-if="pagination"
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
            :page-size="pagination.per_page"
            :total="pagination.total">
        </el-pagination>
    </div>
</template>

<script>
import * as Api from '@/api/category/category'
import CourseCard from '@/components/CourseCard/CourseCard.vue'
import { switchCategory } from '@/utils/utils'
import NoContent from '@/components/NoContent/NoContent.vue'
import { activityType } from '@/enums/API'

export default {
    name: 'Activity',
    data () {
        return {
            courseData: [],
            currentIndex: '',
            category_id: 1,
            pagination: '',
            noContentData: {
                pic: 'course_pic',
                tip: '暂时没有课程~'
            },
        }
    },
    mounted () {
        this.category_id = switchCategory(this.$route.path)
        this.getActivityCourseList()
    },
    methods: {
        getActivityCourseList (page = 1) {
            let params = {
                category_id: this.category_id,
                page: page
            }
            Api.activityCommunityGroups(activityType.trainingCamp, params, (res, pagination) => {
                this.courseData = res
                this.pagination = pagination
            })
        }
    },
    components: {
        CourseCard,
        NoContent
    }
}
</script>

<style lang="less" scoped>
.ielts-activity {
    width: 1200px;
    margin: 0 auto;
  overflow: hidden;

    & > .content {
        margin-top: 40px;

        & > .lists {
            width: 1200px;
            margin: 0 auto;
            float: left;

            & > .course-card:nth-child(4n) {
                margin-right: 0;
            }

            /deep/ .course-card {
                width: 272px;
                height: 330px;
                float: left;
                margin-right: 37px;
                box-sizing: border-box;
                margin-bottom: 30px;

                & > a {
                    height: 320px;
                    position: relative;
                    border: 1px solid #E6E6E6;
                    border-radius: 5px;
                    background: #fff;

                    & > .top {
                        width: 100%;
                        height: 153px;
                        border-radius: 5px 5px 0 0;
                    }

                    & > .desc {
                        box-sizing: border-box;
                        padding: 18px 16px;
                        margin-top: 0;
                        height: 166px;

                        & > .first-line {
                            font-size: 22px;
                        }

                        & > .second-line {
                            font-size: 14px;
                            margin-bottom: 30px;
                        }

                        & > .third-line {
                            position: absolute;
                            left: 0;
                            bottom: 18px;
                            box-sizing: border-box;
                            padding: 0 16px;
                            align-items: flex-end;

                            & > .left {
                                font-size: 14px;
                            }

                            & > .right {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .slideInUp {
        top: -10px;
    }

    .el-pagination {
        margin-top: 20px;
        float: right;
    }
}
</style>
