import { render, staticRenderFns } from "./CategoryActivity.vue?vue&type=template&id=0a3e9daf&scoped=true"
import script from "./CategoryActivity.vue?vue&type=script&lang=js"
export * from "./CategoryActivity.vue?vue&type=script&lang=js"
import style0 from "./CategoryActivity.vue?vue&type=style&index=0&id=0a3e9daf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3e9daf",
  null
  
)

export default component.exports